import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MieszkancyListaLista from './MieszkancyListaLista';
import MieszkancyUliceBox from './MieszkancyUliceBox';
import MieszkancyRodzajLokaluBox from './MieszkancyRodzajLokaluBox';

import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';
import DialogOk from '../DialogOk';

const MieszkancyLista = (props) =>{

  const {   mieszkancy, setMieszkaniec, udzialyKontaAkt, setEditDataOd, setEditDataDo, grupaUdzial, 
    setGrupaUdzial, setUser, grupy, wybraneGrupyUdzial, setWybraneGrupyUdzial, ulice, ulicaId, lokale, setUlicaId, setEditKonto, 
    mieszkaniec, bezdomni, setBezdomny, setAddGrupe, rodzajLokalu, setRodzajLokalu, setWybranyOdczyt, users_w, state} = props;
  
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect( () => {
    let grupyTemp = [];
    if (ulicaId > 0) {
      grupy?.forEach( g => {
        const lokal = lokale?.find( l => l.id == g?.id_lokal );
        if ( lokal?.rodzaj === rodzajLokalu && lokal?.ulica == ulicaId )
          grupyTemp.push(g)
      })
    }  
    setWybraneGrupyUdzial(grupyTemp)
  }, [ulicaId, grupy, mieszkancy, rodzajLokalu])

  return (
      <motion.div 
        className="content listaMieszkancow"
        variants={wstepy}
        initial="initial"
        animate="animate"
      >
          <DialogOk
            open={open}
            setOpen={setOpen}
            message={message}
          />

          <MieszkancyUliceBox
            ulice={ulice}
            ulicaId={ulicaId}
            setUlicaId={setUlicaId}
            setMieszkaniec={setMieszkaniec}
            setUser={setUser}
            setGrupaUdzial={setGrupaUdzial}
            setEditKonto={setEditKonto}
            setAddGrupe={setAddGrupe}
            rodzajLokalu={rodzajLokalu}
            setRodzajLokalu={setRodzajLokalu}
            setWybranyOdczyt={setWybranyOdczyt}
          />
          <MieszkancyRodzajLokaluBox
            ulice={ulice}
            ulicaId={ulicaId}
            setUlicaId={setUlicaId}
            setMieszkaniec={setMieszkaniec}
            setUser={setUser}
            setGrupaUdzial={setGrupaUdzial}
            setEditKonto={setEditKonto}
            setAddGrupe={setAddGrupe}
            rodzajLokalu={rodzajLokalu}
            setRodzajLokalu={setRodzajLokalu}
            setWybranyOdczyt={setWybranyOdczyt}
          />
          <MieszkancyListaLista
            mieszkancy={mieszkancy}
            ulice={ulice}
            setMieszkaniec={setMieszkaniec}
            udzialyKontaAkt={udzialyKontaAkt}
            setEditDataOd={setEditDataOd}
            setEditDataDo={setEditDataDo}
            wybraneGrupyUdzial={wybraneGrupyUdzial}
            lokale={lokale}
            grupy={grupy}
            grupaUdzial={grupaUdzial}
            setGrupaUdzial={setGrupaUdzial}
            setUser={setUser}
            setEditKonto={setEditKonto}
            ulicaId={ulicaId}
            bezdomni={bezdomni}
            setBezdomny={setBezdomny}
            mieszkaniec={mieszkaniec}
            setAddGrupe={setAddGrupe}
            rodzajLokalu={rodzajLokalu}
            setWybranyOdczyt={setWybranyOdczyt}
            state={state}
            users_w={users_w}
          />
      </motion.div>
  )
}

export default MieszkancyLista;

