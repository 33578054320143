import React, {useEffect, useState } from 'react';
import { useLocation, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import GoBackBtn from '../../GoBackBtn.js';
import MieszkaniecWydruk from '../../Mieszkaniec/Wydruk/MieszkaniecWydruk.js';
import MieszkancyRozliczenieSaldo from './MieszkancyRozliczenieSaldo';
import MieszkancyRozliczenieListTHead from './MieszkancyRozliczenieListTHead.js'
import MieszkancyRozliczenieListTBody from './MieszkancyRozliczenieListTBody.js';
import Loader from '../../Loader.js';
import { dataFormatMsc, addOneDay, nextMonth } from '../../../functions/dataFormat.js';
import { znajdzAktualny } from '../../../functions/stawki.js';
import { adres } from "../../../functions/ulice.js";
import { ikona } from "../../../functions/ikony.js";
import { usunKluczNaN } from '../../../functions/tablice.js';

import {
    fetchMieszkancy,
    fetchWspolnoty,
    fetchGrupy,
    fetchGrupyAkt,
    fetchWersje,
    fetchLokale,
    fetchUlice,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchUdzialyKontaAll,
    fetchStawki, 
    fetchPrzelewy,
    fetchStawkiJednorazowe,
    fetchStawkiJednorazoweInd
} from '../../../Redux/Actions.js';

const MieszkancyRozliczenieList = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const id_user = queryParams.get("id");
    const idw = queryParams.get("idw");
    const grupa_numer = queryParams.get("g");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mieszkancy = useSelector( state => state?.mieszkancy?.data );
    const mieszkaniec = mieszkancy ? mieszkancy?.filter( m => m.id_user == id_user)[0] : undefined;
    const grupy = useSelector( state => state?.grupy?.data);
    const grupyAkt = useSelector( state => state?.grupyAkt?.data);
    const grupyAkt_u = grupyAkt ? grupyAkt?.filter( g => g.id_user == id_user) : undefined;
    const wersje = useSelector( state => state?.wersje?.data);
    const lokale = useSelector(state => state?.lokale?.data);
    const ulice = useSelector(state => state?.ulice?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const stawki = useSelector( state => state?.stawki?.data );
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    
    const location = useLocation();
    const { state } = location;
    const czynszeMieszkaniec = state?.from?.czynszeMieszkaniec;
    const przelewyMieszkaniec = state?.from?.przelewyMieszkaniec;
    const [stawkiOst, setStawkiOst] = useState([]);
    const [czynszeMsc, setCzynszeMsc] = useState(undefined);

    const [czynszeGrupa, setCzynszeGrupa] = useState([]);
    const [ miesiac, setMiesiac ] = useState();
    const [ listaMsc, setListaMsc ] = useState([]);
    const [ grupyUdzialyMieszkaniec, setGrupyUdzialyMieszkaniec ] = useState([]);
    const [czyWydruk, setCzyWydruk] = useState(false);
    const [czynszeMieszkaniecBezNaN, setCzynszeMieszkaniecBezNan] = useState([]);
    const [loaderOn, setLoaderOn] = useState(true);

    const [grupy_a, setGrupy_a] = useState([]);
    const [udzialyKonta_a, setUdzialyKonta_a] = useState([]);
    const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([]);

    const [ind, setInd] = useState([]); 

    useEffect( () => {
        const uniqueOpisSet = new Set();

        czynszeMsc?.forEach(obj => {
            if (Array.isArray(obj.ind)) {
                obj.ind.forEach(item => {
                    if (item.opis) {
                        uniqueOpisSet.add(item.opis);
                    }
                });
            }
        });
        setInd(Array.from(uniqueOpisSet))
    },[czynszeMsc])

    useEffect( () => setStawkiOst(znajdzAktualny(stawki, miesiac)), [miesiac, stawki]);

    useEffect( () => {
        setUdzialyKonta_a( udzialyKontaAkt?.find(u => u.id_wspolnoty == idw && u.id_user == id_user && u.grupa_numer == grupa_numer) );
        setGrupy_a( grupy?.filter( g => g?.id_user == id_user && g.grupa_numer == grupa_numer) )
    },[udzialyKontaAkt, id_user, grupa_numer, grupy]);

    useEffect( () => setTablicaCzynszeAkt(czynszeGrupa?.find( t => t?.msc == miesiac)), [czynszeGrupa, miesiac])
    useEffect( () => setCzynszeMieszkaniecBezNan( usunKluczNaN(czynszeGrupa) ), [czynszeGrupa]);

    useEffect( ()  => czynszeMieszkaniecBezNaN && czynszeMieszkaniecBezNaN?.length ? setLoaderOn(false) : setLoaderOn(true), [czynszeMieszkaniecBezNaN])

    useEffect( () => setCzynszeGrupa( czynszeMieszkaniec?.grupy_udzial_msc?.find( c => c?.grupa_numer == grupa_numer)?.temp_msc ), [czynszeMieszkaniec, grupa_numer]);

    useEffect( () => setMiesiac( listaMsc[0] ), [listaMsc])
    
    useEffect(()=>{
        dispatch(fetchMieszkancy(idw)); 
        dispatch(fetchWspolnoty(idw))
        dispatch(fetchGrupy(idw)); 
        dispatch(fetchUdzialyKonta(idw));
        dispatch(fetchUdzialyKontaAkt(idw));
        dispatch(fetchUdzialyKontaAll(idw));
        dispatch(fetchLokale(idw));
        dispatch(fetchUlice(idw));
        dispatch(fetchPrzelewy(idw)); 
        dispatch(fetchStawki(idw)); 
        dispatch(fetchStawkiJednorazowe(idw));
        dispatch(fetchStawkiJednorazoweInd(idw));
    },[dispatch, idw]);

    useEffect( () => dispatch(fetchWersje(grupy)), [dispatch, grupy]);
    useEffect( () => dispatch(fetchGrupyAkt(grupy, wersje)), [dispatch, grupy, wersje]);
    useEffect( () => setGrupyUdzialyMieszkaniec(udzialyKonta?.find( d => d.id_user == id_user && d.grupa_numer == grupa_numer)), [id_user, grupa_numer, udzialyKonta]);

    useEffect(()=> {
        
        let minData = addOneDay(grupyUdzialyMieszkaniec?.data_od);
    
        function dodajMiesiac(d) {
            d.setMonth(d.getMonth() + 1);
            return d;
        }
        const dataObecna = new Date();
        const dataMiesiacPoObecnej = nextMonth(new Date(dataObecna));
        const tablicaMiesiecy = [];
        let aktualnaData = new Date(minData.getFullYear(), minData.getMonth(), 1);
        while (aktualnaData <= dataMiesiacPoObecnej) {
            tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
            aktualnaData = dodajMiesiac(aktualnaData);
        }
        setListaMsc(tablicaMiesiecy)
      },[grupyUdzialyMieszkaniec, mieszkaniec])

    const wybierzMiesiac = (m) => {
        setMiesiac(m);
        showPrint();
    }

    const showPrint = () => setCzyWydruk( prevState => !prevState );

    const lokaleGrupy = grupyAkt_u?.filter( grupa => grupa.id_user == id_user && grupa.grupa_numer == grupa_numer );

    const lokaleTabela = lokaleGrupy?.map( (lokal, i ) => {
        return (
          <li key={i}>
            {ikona(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.rodzaj)} &nbsp; {`${adres(lokale?.filter( l => l.id === lokal?.id_lokal )[0]?.ulica, ulice, lokal?.id_lokal, lokale)}`} 
          </li>
        )
    });

    const handleLink = (link_wspolnota, link_user, link_grupa) => navigate(`/mieszkancy?idw=${link_wspolnota}`, { state: { link_user, link_grupa } });

    return (
        <div className="content">
            <Loader stan={loaderOn}/>
            <h1 className="nazwa">
                Lista rozliczeń mieszkańca  
            </h1>
            <h3>
                { mieszkaniec?.imie_nazwisko /*+ " (" + mieszkaniec[0]?.ulica + " " + mieszkaniec[0]?.numer_domu +"/" + mieszkaniec[0]?.numer_lokalu + ")"*/}
            </h3>
            <GoBackBtn />

            <div 
                className='lokale pointer'
                onClick={()=>handleLink(idw, id_user, grupa_numer)}
            >
                <h5>{`Lokale w tej grupie czynszowej numer ${grupa_numer}:`}</h5>
                <ul style={{"listStyle": "none"}}>
                    {lokaleTabela}
                </ul>
            </div>

            <table className="lista tabela naleznosci">
                <MieszkancyRozliczenieListTHead
                    grupa_numer={grupa_numer}
                    czynszeMieszkaniec={czynszeMieszkaniec}
                    stawki={stawkiOst} 
                />
                <MieszkancyRozliczenieListTBody
                    grupa_numer={grupa_numer}
                    czynszeMieszkaniec={czynszeMieszkaniec}
                    listaMsc={listaMsc}
                    wybierzMiesiac={wybierzMiesiac}
                    stawki={stawkiOst} 
                />
            </table>
            
             {/* <MieszkancyRozliczenieSaldo 
                czynsze={czynszeGrupa} 
                przelewy={przelewyMieszkaniec}
                wybierzMiesiac={wybierzMiesiac}
                listaMsc={listaMsc}
                showPrint={showPrint}
            /> */}

            {czyWydruk && (
                <MieszkaniecWydruk 
                    udzialyKonta_a={udzialyKonta_a}
                    showPrint={showPrint}
                    mieszkaniec={mieszkaniec}
                    tablicaCzynszeAkt={tablicaCzynszeAkt}
                    stawkiOst={stawkiOst}
                    stawkiJednorazowe={stawkiJednorazowe}
                    stawkiJednorazoweInd={stawkiJednorazoweInd}
                    lokale={lokale}
                    ulice={ulice}
                    grupy_a={grupy_a}
                    miesiac={miesiac}
                    grupa_numer={grupa_numer}
                />
            ) }
        </div>
    )
}

export default MieszkancyRozliczenieList;