import React, { useContext, useState, useEffect } from "react";
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

import { 
  fetchWspolnoty,
  fetchMieszkancy, 
  fetchUsers,
  fetchSerwis
} from '../../../Redux/Actions.js'; 
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions.js';
import { AuthContext } from "../../../Context/AuthProvider.js";
import Loader from "../../Loader.js";

import WspolnotyListaTHead from "./WspolnotyListaTHead.js";
import WspolnotyListaTBody from "./WspolnotyListaTBody.js";
// import WspolnotyListaWyszukaj from "./WspolnotyListaWyszukaj.js";

const WspolnotyLista = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);
    
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const users = useSelector( state => state?.users?.data);
    
    useEffect( ()=> {
      dispatch(fetchWspolnoty()); 
      dispatch(fetchMieszkancy()); 
      dispatch(fetchUsers());
      dispatch(fetchSerwis());
    },[dispatch]);

    const [showLens, setShowLens] = useState(false);
    const handleLens = () => setShowLens(prevState => !prevState);
    const [ isFocus, setFocus ] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [user_id, setUser_id] = useState([]);
    
    const [loaderOn, setLoaderOn] = useState(true);

    const openHint = ( (id, idw) => {
      setFocus(false);
      navigate(`/mieszkancy?id=${id}&idw=${idw}`);
    });

    const uniqueUsersMap = new Map();
    mieszkancy?.forEach(user => {
      if (!uniqueUsersMap.has(user.id_user) || new Date(user.data_od) > new Date(uniqueUsersMap.get(user.id_user).data_od)) {
          uniqueUsersMap.set(user.id_user, user);
      }
    });

    useEffect( () => {
        setUser_id( users?.find( u => u.user == user)?.id )
    },[users, user])
    
    const uniqueUsers = Array.from(uniqueUsersMap.values());

    const [mieszkaniec, setMieszkaniec] = useState();

    useEffect( () => {
        setMieszkaniec( mieszkancy?.find( m => m.id_user == user_id) )
    },[mieszkancy, user_id]);


    useEffect( () => {
      const fetchData = async () => {
        if (wspolnoty?.length > 0 ) {
          setLoaderOn(false);
        }
      };
      fetchData();
    },[wspolnoty])
  
    return (
        <motion.div
          className="content"
          variants={wstepy}
          initial="initial"
          animate="animate"
        >
          <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Lista wspólnot
            </h1>
            <div 
              className="buttons"
              style={{width: "100%", justifyContent: "space-between"}}
            >
              <NavLink to="/wspolnoty/dodaj">
                  <button>Dodaj wspólnotę</button>
              </NavLink>
              {/* <WspolnotyListaWyszukaj /> */}
            </div>
            
            <ul 
              className="lista"
              style={{paddingRight: "15px"}}
            >
              <WspolnotyListaTHead />
            </ul>
            <ul className="lista wspolnoty">
              { 
                <WspolnotyListaTBody 
                  wspolnoty={wspolnoty}
                /> 
              }
            </ul>

        </motion.div>
    )
}

export default WspolnotyLista;

