import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { 
    fetchWspolnota, 
    fetchMieszkancy,
    fetchMieszkancyAll,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt, 
    fetchGrupy,
    fetchGrupyAkt,
    fetchUlice,
    fetchLokale,
    fetchPrzelewy,
    fetchUdzialyKontaAll,
    fetchPlanowaneZuzycie,
    fetchStawki,
    fetchStawkiJednorazowe,
    fetchStawkiJednorazoweInd,
    fetchStawkiInd,
    fetchUsers
  } from '../../Redux/Actions.js'; 
import MieszkancyLista from './MieszkancyLista';
import MieszkaniecDane from './MieszkaniecDane.js';
import MieszkancyAddForm from './MieszkancyAddForm.js';
import { NavLink } from 'react-router-dom';
import Loader from '../Loader.js';

const Mieszkancy = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const id_wspolnoty = queryParams.get("idw");
    
    const dispatch = useDispatch();

    useEffect( ()=> {
        dispatch(fetchWspolnota(id_wspolnoty));  
        dispatch(fetchMieszkancy(id_wspolnoty)); 
        dispatch(fetchMieszkancyAll(id_wspolnoty));
        dispatch(fetchUdzialyKonta(id_wspolnoty));
        dispatch(fetchUdzialyKontaAkt(id_wspolnoty));
        dispatch(fetchUdzialyKontaAll(id_wspolnoty));
        dispatch(fetchGrupy(id_wspolnoty));
        dispatch(fetchGrupyAkt(id_wspolnoty));
        dispatch(fetchUlice(id_wspolnoty));
        dispatch(fetchLokale(id_wspolnoty));
        dispatch(fetchPrzelewy(id_wspolnoty));
        dispatch(fetchPlanowaneZuzycie(id_wspolnoty));
        dispatch(fetchStawki(id_wspolnoty)),
        dispatch(fetchStawkiJednorazowe(id_wspolnoty)),
        dispatch(fetchStawkiJednorazoweInd(id_wspolnoty)),
        dispatch(fetchStawkiInd(id_wspolnoty)),
        dispatch(fetchUsers(id_wspolnoty))
    },[dispatch, id_wspolnoty]);
    
    const location = useLocation();

    const state = location.state; 

    const wspolnotaArr = useSelector( state => state?.wspolnota?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data );
    const mieszkancyAll = useSelector( state => state?.mieszkancyAll?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const przelewy = useSelector( state => state?.przelewy?.data );
    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data);
    const users = useSelector( state => state?.users?.data );
    
    const [loaderOn, setLoaderOn] = useState(false);

    const [mieszkaniec, setMieszkaniec] = useState(undefined);
    const [user, setUser] = useState();
    const [grupaUdzial, setGrupaUdzial] = useState(undefined);
    const [mieszkaniecAll, setMieszkaniecAll] = useState(undefined);
    const [wspolnota, setWspolnota] = useState([]);
    const [add, setAdd] = useState(false);
    const [editDataOd, setEditDataOd] = useState({id: 0, show: false, dataOd: ""});
    const [editDataDo, setEditDataDo] = useState({id: 0, show: false, dataDo: ""});
    const [grupy_g, setGrupy_g] = useState(undefined);
    const [wybraneGrupyUdzial, setWybraneGrupyUdzial] = useState([]);
    const [taGrupaUdzial, setTaGrupaUdzial] = useState([]);
    const [bezdomni, setBezdomni] = useState([]);
    const [bezdomny, setBezdomny] = useState([]);
    const [addGrupe, setAddGrupe] = useState(false);
    const [rodzajLokalu, setRodzajLokalu] = useState("mieszkanie");
    const [users_w, setUsers_w] = useState(undefined);

    const [email, setEmail] = useState({visible: false, value: ""});
    const [imie, setImie] = useState({visible: false, value: ""});
    const [telefon, setTelefon] = useState({visible: false, value: ""});
    const [zarzad, setZarzad] = useState({visible: false, value: "", data: ""});
    const [nota, setNota] = useState({visible: false, value: "", data: ""});
    const [notaDane, setNotaDane] = useState({visible: false, notaNip: "", notaNazwa: "", notaAdres: "", notaKodMiasto: "", edit: false});

    const [ulicaId, setUlicaId] = useState(0);

    const [wybranyOdczyt, setWybranyOdczyt] = useState(undefined);

    useEffect( () => setUsers_w( users?.filter( u => u?.id_wspolnoty == id_wspolnoty)),[users, id_wspolnoty] );

    useEffect( () => {
        setEmail( prevState => ({...prevState, value: mieszkaniec?.email}));
        setTelefon( prevState => ({...prevState, value: mieszkaniec?.telefon}));
        setImie( prevState => ({...prevState, value: mieszkaniec?.imie_nazwisko}));
        const tempZarzad = mieszkaniec?.czy_zarzad == 1 ? "tak" : "nie";
        setZarzad( prevState => ({...prevState, value: tempZarzad}));
        const tempNota = mieszkaniec?.czy_nota == 1 ? "tak" : "nie";
        setNota( prevState => ({...prevState, value: tempNota}));
        setNotaDane({notaNip: "", notaNazwa: "", notaAdres: "", notaKodMiasto: "", edit: false});
    },[mieszkaniec, grupaUdzial])

    useEffect( () => setTaGrupaUdzial( udzialyKonta?.filter( u => u?.id_user == grupaUdzial?.id_user && u?.grupa_numer == grupaUdzial?.grupa_numer ) ), [udzialyKonta, grupaUdzial]);
    
    useEffect( () => setWspolnota( wspolnotaArr?.find( w => w.id == id_wspolnoty) ),[wspolnotaArr, id_wspolnoty]);
    
    useEffect( () => {
        let temp = [];
        mieszkancy?.forEach( m => {
            if (udzialyKontaAkt?.filter( u => u?.id_user == m?.id_user)?.length === 0)
                temp.push(m);
        })
        setBezdomni(temp)
    },[mieszkancy, udzialyKontaAkt])

    useEffect(() => setGrupy_g( grupy?.filter( g=> g?.id_user == grupaUdzial?.id_user && g?.grupa_numer == grupaUdzial?.grupa_numer ) ), [grupy, grupaUdzial]);
    
    useEffect( () => setMieszkaniec( mieszkancy?.find( m => m.id_user == mieszkaniec?.id_user ) ), [mieszkancy, mieszkaniec]);
    useEffect( () => setMieszkaniecAll( mieszkancyAll?.filter( m => m.id_user == mieszkaniec?.id_user)), [mieszkancyAll, mieszkaniec]);

    useEffect( () => {
        if (state && state?.link_user > 0) {
            setUser( users_w?.find( u => u?.id == state?.link_user));
            setMieszkaniec( mieszkancy?.find( m => m?.id_user == state?.link_user));
            setGrupaUdzial( udzialyKontaAkt?.find( u => u?.id_user == state?.link_user && u?.grupa_numer == state?.link_grupa ) );
        }
    }, [state, users_w, mieszkancy, udzialyKontaAkt ]);

    const handleAdd = () => {
        setAdd(true);
        setMieszkaniec(undefined);
        setImie({visible: false, value: ""});
        setTelefon({visible: false, value: ""});
        setEmail({visible: false, value: ""});
        setNotaDane({notaNip: "", notaNazwa: "", notaAdres: "", notaKodMiasto: ""});
    }

    const handleList = () => {
        setAdd(false);
        setMieszkaniec(undefined);
        setImie({visible: false, value: ""});
        setTelefon({visible: false, value: ""});
        setEmail({visible: false, value: ""});
        setNota({visible: false, value: "", data: ""});
        setZarzad({visible: false, value: "", data: ""});
        setNotaDane({notaNip: "", notaNazwa: "", notaAdres: "", notaKodMiasto: "", edit: false});
    }

    useEffect( () =>{
        if (grupaUdzial)
            setGrupaUdzial( udzialyKontaAkt?.find( u => u?.id_user == grupaUdzial?.id_user && u?.grupa_numer == grupaUdzial?.grupa_numer) )
    } ,[udzialyKontaAkt]);

    useEffect( () => {
        if (mieszkancy && mieszkancy?.length > 0)
            setLoaderOn(false)
        // const timer = setTimeout(() => {
        //     setLoaderOn(false);
        //   }, 1000); 
        //   return () => clearTimeout(timer);
    },[mieszkancy])


    return (
        <div className='mieszkancy'>
            <div className='left'>
                <h1 className="nazwa">
                    Mieszkańcy
                </h1>
                <h3>
                    { wspolnota?.name }
                </h3>
                <div className='buttons'>
                    <NavLink to="/wspolnoty"> 
                        <button>
                            Wróć do listy WM
                        </button>
                    </NavLink>

                    <button
                        onClick={handleAdd}
                    >
                        Dodaj mieszkańca
                    </button>
                    <button
                        onClick={handleList}
                    >
                        Pokaż listę
                    </button>
                </div>
                <div className='dane'>
                    {add ? (
                        <MieszkancyAddForm
                            setAdd={setAdd}
                            idw={id_wspolnoty}
                        />
                    ) : (
                        <MieszkancyLista
                            mieszkancy={mieszkancy}
                            setMieszkaniec={setMieszkaniec}
                            ulice={ulice}
                            ulicaId={ulicaId}
                            lokale={lokale}
                            setUlicaId={setUlicaId}
                            setAdd={setAdd}
                            udzialyKontaAkt={udzialyKontaAkt}
                            setEditDataOd={setEditDataOd}
                            setEditDataDo={setEditDataDo}
                            grupaUdzial={grupaUdzial}
                            setGrupaUdzial={setGrupaUdzial}
                            users_w={users_w}
                            setUser={setUser}
                            grupy={grupy}
                            wybraneGrupyUdzial={wybraneGrupyUdzial}
                            setWybraneGrupyUdzial={setWybraneGrupyUdzial}
                            rodzajLokalu={rodzajLokalu}
                            setRodzajLokalu={setRodzajLokalu}
                            bezdomni={bezdomni}
                            setBezdomny={setBezdomny}
                            mieszkaniec={mieszkaniec}
                            setAddGrupe={setAddGrupe}
                            setWybranyOdczyt={setWybranyOdczyt}
                            state={state}
                        />
                    )} 
                </div>
            </div>
            {
                mieszkaniec ? (
                    <MieszkaniecDane
                        wspolnotaArr={wspolnotaArr}
                        przelewy={przelewy}
                        mieszkancy={mieszkancy}
                        mieszkaniec={mieszkaniec}
                        mieszkaniecAll={mieszkaniecAll}
                        wspolnota={wspolnota}
                        grupy={grupy}
                        udzialyKonta={udzialyKonta}
                        udzialyKontaAkt={udzialyKontaAkt}
                        lokale={lokale}
                        planowaneZuzycie={planowaneZuzycie}
                        stawkiJednorazowe={stawkiJednorazowe}
                        stawkiJednorazoweInd={stawkiJednorazoweInd}
                        editDataOd={editDataOd}
                        editDataDo={editDataDo}
                        setEditDataOd={setEditDataOd}
                        setEditDataDo={setEditDataDo}
                        user={user}
                        grupaUdzial={grupaUdzial}
                        grupy_g={grupy_g}
                        taGrupaUdzial={taGrupaUdzial}
                        bezdomny={bezdomny}
                        ulicaId={ulicaId}
                        addGrupe={addGrupe}
                        setAddGrupe={setAddGrupe}
                        imie={imie}
                        setImie={setImie}
                        telefon={telefon}
                        setTelefon={setTelefon}
                        email={email}
                        setEmail={setEmail}
                        stawki={stawki}
                        nota={nota}
                        setNota={setNota}
                        zarzad={zarzad}
                        setZarzad={setZarzad}
                        notaDane={notaDane}
                        setNotaDane={setNotaDane}
                        wybranyOdczyt={wybranyOdczyt}
                        setWybranyOdczyt={setWybranyOdczyt}
                    />
                ) : ""
            } 

            <Loader
                stan={loaderOn}
            />
        </div>
    )
}

export default Mieszkancy;