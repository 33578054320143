import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import refreshReducer from './refreshReducer';
import wspolnotyReducer from './wspolnotyReducer';
import mieszkancyReducer from './mieszkancyReducer';
import mieszkancyAllReducer from './mieszkancyAllReducer';
import mieszkancyAllWReducer from './mieszkancyAllWReducer';
import mieszkaniecReducer from './mieszkaniecReducer';
import usersReducer from './usersReducer';
import usersAllReducer from './usersAllReducer';
import przelewyReducer from './przelewyReducer';
import lokaleReducer from './lokaleReducer';
import lokaleAllReducer from './lokaleAllReducer';
import uliceReducer from './uliceReducer';
import uliceAllReducer from './uliceAllReducer';
import odczytyReducer from './odczytyReducer';
import stawkiReducer from './stawkiReducer';
import stawkiIndReducer from './stawkiIndReducer';
import stawkiJednorazoweReducer from './stawkiJednorazoweReducer';
import stawkiJednorazoweIndReducer from './stawkiJednorazoweIndReducer';
import stawkiJednorazoweIndUReducer from './stawkiJednorazoweIndUReducer';
import udzialyKontaReducer from './udzialyKontaReducer';
import udzialyKontaAktReducer from './udzialyKontaAktReducer';
import udzialyKontaAllReducer from './udzialyKontaAllReducer';
import grupyReducer from './grupyReducer';
import grupyUReducer from './grupyUReducer';
import grupyAllReducer from './grupyAllReducer';
import grupyAktReducer from './grupyAktReducer';
import wersjeReducer from './wersjeReducer';
import tablicaPrzelewyReducer from './TablicaCzynszeReducer';
import tablicaPrzelewyPelneReducer from './TablicaPrzelewyPelneReducer';
import tablicaCzynszeReducer from './TablicaCzynszeReducer';
import saldoReducer from './saldoReducer';
import planowaneZuzycieReducer from './planowaneZuzycieReducer';
import planowaneZuzycieUReducer from './planowaneZuzycieUReducer';
import loginUserReducer from './loginUserReducerjs';
import userPasswordSendReducer from './userPasswordSendReducer';
import userReducer from './userReducer';
import logiReducer from './logiReducer';
import notyReducer from './notyReducer';
import usterkiReducer from './usterkiReducer';
import glosowanieKartyReducer from './glosowanieKartyReducer';
import glosowaniePytaniaReducer from './glosowaniePytaniaReducer';
import glosowanieOdpowiedziReducer from './glosowanieOdpowiedziReducer';
import glosowanieUchwalyReducer from './glosowanieUchwalyReducer';
import serwisReducer from './serwisReducer';
import bankiReducer from './bankiReducer';
import zaleglosciPowiadomieniaReducer from './zaleglosciPowiadomieniaReducer';
import credReducer from './credReducer';
import wspolnotaReducer from './wspolnotaReducer';
import przelewyUReducer from './przelewyUReducer';
import udzialyKontaUReducer from './udzialyKontaUReducer';
import udzialyKontaAktUReducer from './udzialyKontaAktUReducer';
import udzialyKontaAllAktReducer from './udzialyKontaAllAktReducer';

const rootReducer = combineReducers({
  refresh: refreshReducer,
  wspolnoty: wspolnotyReducer,
  wspolnota: wspolnotaReducer,
  mieszkancy: mieszkancyReducer,
  mieszkancyAll: mieszkancyAllReducer,
  mieszkancyAll_w: mieszkancyAllWReducer,
  mieszkaniec: mieszkaniecReducer,
  users: usersReducer,
  usersAll: usersAllReducer,
  przelewy: przelewyReducer,
  przelewyU: przelewyUReducer,
  lokale: lokaleReducer,
  lokaleAllReducer: lokaleAllReducer,
  ulice: uliceReducer,
  uliceAll: uliceAllReducer,
  odczyty: odczytyReducer,
  stawki: stawkiReducer,
  stawkiInd: stawkiIndReducer,
  stawkiJednorazowe: stawkiJednorazoweReducer,
  stawkiJednorazoweInd: stawkiJednorazoweIndReducer,
  stawkiJednorazoweIndU: stawkiJednorazoweIndUReducer,
  grupy: grupyReducer,
  grupyU: grupyUReducer,
  grupyAll: grupyAllReducer,
  grupyAkt: grupyAktReducer,
  udzialyKonta: udzialyKontaReducer,
  udzialyKontaU: udzialyKontaUReducer,
  udzialyKontaAkt: udzialyKontaAktReducer,
  udzialyKontaAktU: udzialyKontaAktUReducer,
  udzialyKontaAll: udzialyKontaAllReducer,
  udzialyKontaAllAkt: udzialyKontaAllAktReducer,
  wersje: wersjeReducer,
  tablicaPrzelewy: tablicaPrzelewyReducer,
  tablicaPrzelewyPelne: tablicaPrzelewyPelneReducer,
  tablicaCzynsze: tablicaCzynszeReducer,
  saldo: saldoReducer,
  creds: credReducer,
  planowaneZuzycie: planowaneZuzycieReducer,
  planowaneZuzycieU: planowaneZuzycieUReducer,
  loginUser: loginUserReducer,
  userPasswordSend: userPasswordSendReducer,
  user: userReducer,
  logi: logiReducer,
  noty: notyReducer,
  usterki: usterkiReducer,
  glosowanieKarty: glosowanieKartyReducer,
  glosowaniePytania: glosowaniePytaniaReducer,
  glosowanieOdpowiedzi: glosowanieOdpowiedziReducer,
  glosowanieUchwaly: glosowanieUchwalyReducer,
  serwis: serwisReducer,
  banki: bankiReducer,
  zaleglosciPowiadomienia: zaleglosciPowiadomieniaReducer
});

const store = createStore(
  rootReducer, 
  applyMiddleware(thunk),
  // process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
);
  
export default store;

