import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";
import { fetchStawkiInd } from "../../Redux/Actions";
import { translate } from "../../functions/dictionary";

const MieszkaniecDaneStawkiIndAdd = props => {

    const { grupaUdzial, stawki_w, stawkiInd_g, setAddNew } = props;

    const [stawkiAkt, setStawkiAkt] = useState([]);  //ostatnie stawki wspólnoty
    const [stawkiNowe, setStawkiNowe] = useState([])

    const dispatch = useDispatch(); 
    
    
    const [dataOd, setDataOd]  = useState("");

    const [listaUwzglednianychStawek, setListaUwzglednianychStawek] = useState([]);

    useEffect( () => setStawkiAkt( !stawki_w.length ? [] : stawki_w[stawki_w.length - 1] ),[stawki_w]);

    // useEffect( () => setStawkiIndAkt(  !stawkiInd_g.length ? [] : stawkiInd_g[stawkiInd_g.length - 1] ), [stawkiInd_g])

    
    useEffect( () => {

        if (!stawki_w.length) return [];

        const nowaTablica = [];

        Object.keys(stawkiAkt).forEach(klucz => {
            if (klucz.startsWith("czy_") && stawkiAkt[klucz] === "1") {
                nowaTablica.push(klucz.replace("czy_", ""));
            }
        });

        setListaUwzglednianychStawek(nowaTablica)

    },[grupaUdzial, stawkiAkt, stawkiInd_g])

    const handleChangeDataOd = e => setDataOd(e.target.value);
    const handleChangeStawka = (e, stawka) => setStawkiNowe(prevState =>({...prevState, [stawka]: e.target.value} ))
    
    // useEffect( () => {
    //     const wynik = {};
    //     for (const klucz in stawkiAkt) {
    //         if (Object.prototype.hasOwnProperty.call(stawkiAkt,klucz)) {
    //             const czyKlucz = `czy_${klucz}`; 
    //             if (Object.prototype.hasOwnProperty.call(stawkiAkt,klucz) && stawkiAkt[czyKlucz] === "1") {
    //                 wynik[klucz] = stawkiAkt[klucz];
    //             }
    //         }
    //     }

    //     setStawkiCzy(wynik)
    // }, [stawkiAkt]);

    const form = listaUwzglednianychStawek?.map( (stawka, i) => {

        return (
            <li key={i}> 
                <div>
                    {translate(stawka)}
                </div>
                <div>
                    <input 
                        type="number"
                        value={stawkiNowe[stawka]}
                        onChange={(e)=>handleChangeStawka(e, stawka)}
                        placeholder="puste - domyślne"
                    />
                </div>
            </li>
        )
    })

    const handleSave = async () => {

        if (dataOd === "") {
            alert("Wpisz datę")
            return []
        }

        const obj = {
            id_wspolnoty: grupaUdzial?.id_wspolnoty,
            id_user: grupaUdzial?.id_user,
            grupa_numer: grupaUdzial?.grupa_numer,
            data_od: dataOd,
            eksploatacja: null,
            garaz: null,
            strych: null,
            utrzymanie_czystosci: null,
            wywoz_smieci: null,
            fundusz_remontowy: null,
            konserw_domofonu: null,
            konserw_windy: null,
            opl_za_adm: null,
            ciepla_woda: null,
            zimna_woda: null,
            abonament_cw: null,
            co: null,
            energia_el_lokalu: null,
            ryczalt_gaz: null,
            zagosp_terenu: null,
            antena: null,
            komorka: null,
        };

        for (let key in stawkiAkt) {
            if (Object.prototype.hasOwnProperty.call(stawkiAkt, key) ) {
                if (key !== "data_od" && stawkiAkt[key] !== obj[key]) {
                    obj[key] = stawkiNowe[key]; // Zapisz wartości z obu obiektów
                }
            }
        }

        try {
            await axios.post('/api/stawki_ind/dodaj', { obj }).then( response => {
                if (response.status === 200) 
                    dispatch(fetchStawkiInd(grupaUdzial?.id_wspolnoty));
                    setStawkiNowe([])
                    setAddNew(false);  
            })
        } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
        }
        
        
    }

    const handleCancel = () => {
        setStawkiNowe([]);
        setAddNew(false);
    }

    
    return (
        <div className="wsad ramka-inset-shadow p5">
            <div className="form">
                <ul className="lista stawkiInd">
                    <li>
                        <div>
                            Stawka
                        </div>
                        <div>
                            Kwota
                        </div>
                    </li>
                    {form}
                    <li>
                        <div>
                            Data obowiązywania od:
                        </div>
                        <div>
                            <input 
                                type="date"
                                value={dataOd}
                                onChange={handleChangeDataOd}
                            />
                        </div>
                    </li>
                </ul>
                <div className="buttons">
                    <button onClick={handleSave}>
                        Zapisz
                    </button>
                    <button onClick={handleCancel}>
                        Anuluj
                    </button>
                </div>
            </div>  
        </div>
    )
}

export default MieszkaniecDaneStawkiIndAdd;