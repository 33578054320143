import axios from "axios";
import { wersjaMax, grupa_max,grupa_udzial_akt, mieszkancyMax, grupy_all_max_akt } from '../functions/grupy';
import { getTablicaCzynsze2, getTablicaPrzelewy2, getSaldo } from '../functions/tablice';
import { sortByTxt } from "../functions/utilities";

// if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = 'https://system.bonadministrator.pl/';
// } else {
//   axios.defaults.baseURL = 'http://localhost:3001/';
// }

if (window.location.hostname === 'system.bonadministrator.pl') {
  axios.defaults.baseURL = 'https://system.bonadministrator.pl/';
} else if (window.location.hostname === 'test.bonadministrator.pl') {
  axios.defaults.baseURL = 'https://test.bonadministrator.pl/';
} else {
  axios.defaults.baseURL = 'http://localhost:3001/';
}

export const setUserPasswordSend = data => ({
  type: 'SET_USER_PASSWORD_SEND',
  payload: data
})
export const fetchUserPasswordSend = () => {
  return (dispatch) => {
    axios.get("api/password/send/history")
    .then(response => {
      dispatch(setUserPasswordSend(response.data));
    })
    .catch(error => console.error('There was an error!', error));
  }
}

export const setUser = data => ({
  type: 'SET_USER',
  payload: data
});
export const fetchUser = i => {
  return (dispatch) => {
    dispatch(setUser(i));
  }
}

export const setCred = data => ({
  type: 'SET_CRED',
  payload: data
})
export const fetchCred = (cred) => {
  return (dispatch) => {
      dispatch(setCred(cred));
  }
}

export const setLoginUser = data => ({
  type: 'SET_LOGIN_USER',
  payload: data
})
export const fetchLoginUser = login => {
  return (dispatch) => {
    dispatch(setLoginUser(login));
  }
}

export const setZaleglosciPowiadomienia = data => ({
  type: 'SET_ZALEGLOSCI_POWIADOMIENIA',
  payload: data
})
export const fetchZaleglosciPowiadomienia = () => {
  return (dispatch) => {
    axios.get("/api/zaleglosci/")
      .then(response => {
        dispatch(setZaleglosciPowiadomienia(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  }
}

export const setWspolnoty = (data) => ({
  type: 'SET_WSPOLNOTY',
  payload: data,
});
export const fetchWspolnoty = () => {
  return (dispatch) => {
    axios.get("api/lista_wspolnot")
      .then(response => {
        dispatch(setWspolnoty(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
};

export const setWspolnota = (data) => ({
  type: 'SET_WSPOLNOTA',
  payload: data,
});
export const fetchWspolnota = (id) => {
  return (dispatch) => {
    axios.get("/api/wspolnota", {
      params: { id }
    } )
      .then(response => {
        dispatch(setWspolnota(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
};


export const setUsers = data => ({
  type: 'SET_USERS',
  payload: data
})
export const fetchUsers = (id) => {
  return (dispatch) => {
    axios.get("api/users", {
      params: { id }
    } )
      .then(response => {
        if (Array.isArray(response.data))
        {
          dispatch(setUsers(response.data));
        }
        else {
          console.log("błąd");
          dispatch(setUsers([{"błąd": "błąd"}]));
        }
          
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUsersAll = data => ({
  type: 'SET_USERS_ALL',
  payload: data
})
export const fetchUsersAll = (id) => {
  return (dispatch) => {
    axios.get("api/users-all" )
      .then(response => {
          dispatch(setUsersAll(response.data));          
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setMieszkaniec = (data) => ({
  type: 'SET_MIESZKANIEC',
  payload: data,
});
export const fetchMieszkaniec = () => {
  return (dispatch) => {
    axios.get("/api/mieszkaniec/dane")
      .then(response => {
        const max = mieszkancyMax(response.data);
        if (max?.length > 0)
          dispatch(setMieszkaniec(max));
        else 
          dispatch(setMieszkaniec([]));
      })
      .catch(error => console.error('There was an error!', error));
  };
};

export const setMieszkancy = data => ({
  type: 'SET_MIESZKANCY',
  payload: data
});
export const fetchMieszkancy = (id) => {
  return (dispatch) => {
    axios.get("api/mieszkancy", {
      params: { id }
    } )
      .then(response => {
        if (Array.isArray(response.data))
        {
          dispatch(setMieszkancy(sortByTxt(mieszkancyMax(response.data),"imie_nazwisko")));
        }
        else {
          console.log("błąd");
          dispatch(setMieszkancy([{"błąd": "błąd"}]));
        }
          
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setMieszkancyAll_w = data => ({
  type: 'SET_MIESZKANCY_ALL_W',
  payload: data
});
export const fetchMieszkancyAll_w = () => {
  return (dispatch) => {
    axios.get("api/mieszkancy-all-w")
      .then(response => {
        if (Array.isArray(response.data))
        {
          dispatch(setMieszkancyAll_w(sortByTxt(mieszkancyMax(response.data),"imie_nazwisko")));
        }
        else {
          console.log("błąd");
          dispatch(setMieszkancyAll_w([{"błąd": "błąd"}]));
        }
          
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setMieszkancyAll = data => ({
  type: 'SET_MIESZKANCY_ALL',
  payload: data
});
export const fetchMieszkancyAll = (id) => {
  return (dispatch) => {
    axios.get("api/mieszkancy", {
      params: { id }
    } )
      .then(response => {
        dispatch(setMieszkancyAll(sortByTxt(response.data,"imie_nazwisko")));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setLokale = data => ({
  type: 'SET_LOKALE',
  payload: data
});
export const fetchLokale = (id) => {
  return (dispatch) => {
    axios.get("api/lokale/all", {
      params: { id }
    } )
      .then(response => {
        dispatch(setLokale(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setLokaleAll = data => ({
  type: 'SET_LOKALE_ALL',
  payload: data
});
export const fetchLokaleAll = (id) => {
  return (dispatch) => {
    axios.get("api/lokale/all-w" )
      .then(response => {
        dispatch(setLokaleAll(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setBanki = data => ({
  type: 'SET_BANKI',
  payload: data
});
export const fetchBanki = () => {
  return (dispatch) => {
    axios.get("api/banki")
      .then(response=> {
        dispatch(setBanki(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  }
}

export const setUlice = data => ({
  type: 'SET_ULICE',
  payload: data
});
export const fetchUlice = (id) => {

  return (dispatch) => {
    axios.get("api/ulice", {
      params: { id }
    } )
      .then(response => {
        dispatch(setUlice(sortByTxt(response.data,"nazwa")));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUliceAll = data => ({
  type: 'SET_ULICE_ALL',
  payload: data
});
export const fetchUliceAll = (id) => {

  return (dispatch) => {
    axios.get("api/ulice/all", {
      params: { id }
    } )
      .then(response => {
        dispatch(setUlice(sortByTxt(response.data,"nazwa")));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setOdczyty = data => ({
  type: 'SET_ODCZYTY',
  payload: data
});
export const fetchOdczyty = (id) => {
  return (dispatch) => {
    axios.get('/api/odczyty/', {
      params: { id }
    } )
      .then(response => {
        dispatch(setOdczyty(response.data));
      })
      .catch(error => console.log('There was an error!', error))
  }
}

export const setPlanowaneZuzycie = data => ({
  type: 'SET_PLANOWANE_ZUZYCIE',
  payload: data
})
export const fetchPlanowaneZuzycie = (id) => {

  return (dispatch) => {
    axios.get("api/planowane_zuzycie", {
      params: { id }
    } )
      .then(response => {
        dispatch(setPlanowaneZuzycie(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setPlanowaneZuzycieU = data => ({
  type: 'SET_PLANOWANE_ZUZYCIE_U',
  payload: data
})
export const fetchPlanowaneZuzycieU = () => {

  return (dispatch) => {
    axios.get("api/planowane_zuzycie_user")
      .then(response => {
        dispatch(setPlanowaneZuzycieU(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setLogi = data => ({
  type: 'SET_LOGI',
  payload: data
});
export const fetchLogi = () => {
  return (dispatch) => {
    axios.get("api/logi")
      .then(response => {
        dispatch(setLogi(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setPrzelewy = data => ({
  type: 'SET_PRZELEWY',
  payload: data
});
export const fetchPrzelewy = (id) => {
  return (dispatch) => {
    axios.get("api/przelewy", {
      params: { id }
    } )
      .then(response => {
        dispatch(setPrzelewy(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setPrzelewyU = data => ({
  type: 'SET_PRZELEWY_U',
  payload: data
});
export const fetchPrzelewyU = () => {
  return (dispatch) => {
    axios.get("api/przelewy_user")
      .then(response => {
        dispatch(setPrzelewyU(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawki = data => ({
  type: 'SET_STAWKI',
  payload: data
});
export const fetchStawki = (id) => {
  return (dispatch) => {
    axios.get("api/stawki_wspolnot_arch", {
      params: { id }
    } )
      .then(response => {
        dispatch(setStawki(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawkiInd = data => ({
  type: 'SET_STAWKI_IND',
  payload: data
})
export const fetchStawkiInd = (id) => {
  return (dispatch) => {
    axios.get("api/stawki_ind", {
      params: { id }
    } )
      .then(response => {
        dispatch(setStawkiInd(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawkiJednorazowe = data => ({
  type: 'SET_STAWKI_JEDNORAZOWE',
  payload: data
});
export const fetchStawkiJednorazowe = (id) => {
  return (dispatch) => {
    axios.get("api/wspolnoty_stawki_jednorazowe", {
      params: { id }
    } )
      .then(response => {
        dispatch(setStawkiJednorazowe(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawkiJednorazoweInd = data => ({
  type: 'SET_STAWKI_JEDNORAZOWE_IND',
  payload: data
});
export const fetchStawkiJednorazoweInd = (id) => {
  return (dispatch) => {
    axios.get("api/wspolnoty_stawki_jednorazowe_ind", {
      params: { id }
    } )
      .then(response => {
        dispatch(setStawkiJednorazoweInd(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawkiJednorazoweIndU = data => ({
  type: 'SET_STAWKI_JEDNORAZOWE_IND_U',
  payload: data
});
export const fetchStawkiJednorazoweIndU = () => {
  return (dispatch) => {
    axios.get("api/wspolnoty_stawki_jednorazowe_ind_u")
      .then(response => {
        dispatch(setStawkiJednorazoweIndU(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setGrupy = data => ({
  type: 'SET_GRUPY',
  payload: data
});
export const fetchGrupy = (id) => {
  return (dispatch) => {
    axios.get("api/grupy/", {
      params: { id }
    } )
      .then(response => {
        dispatch(setGrupy(grupa_max(response.data)));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setGrupyU = data => ({
  type: 'SET_GRUPY_U',
  payload: data
});
export const fetchGrupyU = () => {
  return (dispatch) => {
    axios.get("api/grupy_user/")
      .then(response => {
        dispatch(setGrupyU(grupa_max(response.data)));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setGrupyAll = data => ({
  type: 'SET_GRUPY_ALL',
  payload: data
});
export const fetchGrupyAll = (id) => {
  return (dispatch) => {
    axios.get('api/grupy/all', {
      params: { id }
    } )
      .then(response => {
        dispatch(setGrupyAll(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  }
}

export const setWersje = data => ({
  type: 'SET_WERSJE',
  payload: data
});
export const fetchWersje = (grupy) => {
  return (dispatch) => {
    if (grupy && grupy.length > 0) {
      const obliczoneWersje = wersjaMax(grupy);
      dispatch(setWersje(obliczoneWersje));
    } else {
      // console.error('Brak danych grupy do obliczenia wersji');
    }
  };

};

export const setUsterki = data => ({
  type: 'SET_USTERKI',
  payload: data
});
export const fetchUsterki = () => {
  return (dispatch) => {
    axios.get("api/usterki")
      .then(response => {
        dispatch(setUsterki(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setGlosowanieKarty = data => ({
  type: 'SET_GLOSOWANIE_KARTY',
  payload: data
});
export const fetchGlosowanieKarty = () => {
  return (dispatch) => {
    axios.get("api/glosowanie-karty")
      .then(response => {
        dispatch(setGlosowanieKarty(response.data));
      })
      .catch(error => console.log('There was an error!', error));
  }
}

export const setGlosowanieUchwaly = data => ({
  type: 'SET_GLOSOWANIE_UCHWALY',
  payload: data
});
export const fetchGlosowanieUchwaly = () => {
  return (dispatch) => {
    axios.get("api/glosowanie-uchwaly")
      .then(response => {
        dispatch(setGlosowanieUchwaly(response.data));
      })
      .catch(error => console.log('There was an error!', error));
  }
}

export const setGlosowanieOdpowiedzi = data => ({
  type: 'SET_GLOSOWANIE_ODPOWIEDZI',
  payload: data
});
export const fetchGlosowanieOdpowiedzi = () => {
  return (dispatch) => {
    axios.get("api/glosowanie-odpowiedzi")
      .then(response => {
        dispatch(setGlosowanieOdpowiedzi(response.data));
      })
      .catch(error => console.log('There was an error!', error));
  }
}

export const setGlosowaniePytania = data => ({
  type: 'SET_GLOSOWANIE_PYTANIA',
  payload: data
});
export const fetchGlosowaniePytania = () => {
  return (dispatch) => {
    axios.get("api/glosowanie-pytania")
      .then(response => {
        dispatch(setGlosowaniePytania(response.data));
      })
      .catch(error => console.log('There was an error!', error));
  }
}

export const setNoty = data => ({
  type: 'SET_NOTY',
  payload: data
});
export const fetchNoty = (id) => {
  return (dispatch) => {
    axios.get("api/noty", {
      params: { id }
    } )
      .then(response => {
        dispatch(setNoty(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setGrupyAkt = data => ({
  type: 'SET_GRUPY_AKT',
  payload: data
});
export const fetchGrupyAkt = (grupy, wersje) => {
  return (dispatch) => {
    if (grupy && wersje) {
      const temp = grupy_all_max_akt(grupy,wersje);
      dispatch(setGrupyAkt(temp));
    }
    else {
      // console.log('error');
    }
  }
}

export const setUdzialyKonta = data => ({
  type: 'SET_UDZIALY_KONTA',
  payload: data
});
export const fetchUdzialyKonta = (id) => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy/", {
      params: { id }
    } )
      .then(response => { 
        dispatch(setUdzialyKonta(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUdzialyKontaU = data => ({
  type: 'SET_UDZIALY_KONTA_U',
  payload: data
});
export const fetchUdzialyKontaU = () => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy_user/")
      .then(response => { 
        dispatch(setUdzialyKontaU(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUdzialyKontaAkt = data => ({
  type: 'SET_UDZIALY_KONTA_AKT',
  payload: data
});
export const fetchUdzialyKontaAkt = (id) => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy/", {
      params: { id }
    } )
      .then(response => { 
        dispatch(setUdzialyKontaAkt(grupa_udzial_akt(response.data)));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUdzialyKontaAktU = data => ({
  type: 'SET_UDZIALY_KONTA_AKT_U',
  payload: data
});
export const fetchUdzialyKontaAktU = () => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy_user/")
      .then(response => { 
        dispatch(setUdzialyKontaAktU(grupa_udzial_akt(response.data)));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUdzialyKontaAllAkt = data => ({
  type: 'SET_UDZIALY_KONTA_ALL_AKT',
  payload: data
});
export const fetchUdzialyKontaAllAkt = () => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy/all")
      .then(response => { 
        dispatch(setUdzialyKontaAllAkt(grupa_udzial_akt(response.data)));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUdzialyKontaAll = data => ({
  type: 'SET_UDZIALY_KONTA_ALL',
  payload: data
});
export const fetchUdzialyKontaAll = () => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy/all")
      .then(response => { 
        dispatch(setUdzialyKontaAll(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setSerwis = data => ({
  type: 'SET_SERWIS',
  payload: data
})
export const fetchSerwis = ( ) => {
  return (dispatch) => {
    axios.get("api/serwis")
      .then(response => {
        dispatch(setSerwis(response.data));
      })
      .catch(error => console.error('There was an error!', error))
  }
}

export const setTablicaCzynsze = data => ({
  type: 'SET_TABLICA_CZYNSZE',
  payload: data
}); 
export const fetchTablicaCzynsze = ( wspolnoty, mieszkancy, grupy, grupyUniq, udzialyKonta, udzialyKontaUniq, lokale, stawki, wodaZaliczki /*, zaliczki */ ) => {
  return (dispatch)=>{
    if (wspolnoty && mieszkancy && grupy && grupyUniq && udzialyKonta && udzialyKontaUniq && lokale && stawki && wodaZaliczki) {
      const temp = getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, grupyUniq, udzialyKonta, udzialyKontaUniq, lokale, stawki, wodaZaliczki /*, zaliczki */);
      dispatch(setTablicaCzynsze(temp));
    }
    else {
      // console.log('error');
    }
  }
}

export const setTablicaPrzelewy = data => ({
  type: 'SET_TABLICA_PRZELEWY',
  payload: data
});
export const fetchTablicaPrzelewy = ( wspolnoty, udzialyKontaUniq, przelewy ) => {
  return (dispatch) => {
    if (wspolnoty && udzialyKontaUniq && przelewy) {
      const temp = getTablicaPrzelewy2(wspolnoty, udzialyKontaUniq, przelewy);
      dispatch(setTablicaPrzelewy(temp));
    }
    else {
      // console.log('error');
    }
  }
}

export const setSaldo = data => ({
  type: 'SET_SALDO',
  payload: data
});
export const fetchSaldo = ( tablicaPrzelewy, tablicaCzynsze ) => {
  return (dispatch) => {
    // dispatch(fetchDataStart());
    if (tablicaPrzelewy && tablicaCzynsze) {
      const temp = getSaldo(tablicaPrzelewy, tablicaCzynsze);
      dispatch(setSaldo(temp));
    }
    else {
      // console.log('error')
    }
  }
}

