import React from "react";
import { useSelector } from 'react-redux';
import { adresPro } from "../../../functions/ulice";
import { zl, isNumber } from "../../../functions/utilities";
import { mm_rrrr_to_date } from "../../../functions/dataFormat";

const WspolnotaRaportZbiorowyTBody = ( props ) => {

    const { listaMsc, idw, idw_temp, czynszeWspolnota, przelewyWspolnota, visible, czyRok, przelewyRok, czynszeRok, przelewyTotal, czynszeTotal, 
        przelewyRokObecny, czynszeRokObecny, przelewyWidoczne, czynszeWidoczne } = props;

    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);

    const findGrupaZMieszkaniem = (udzialKonto, grupy, lokale) => {
        const grupy_u = grupy?.filter( g => g?.id_user == udzialKonto?.id_user && g?.grupa_numer == udzialKonto?.grupa_numer );
        const wynik = grupy_u.filter(grupa => {
            const lokal = lokale.find(lokal => lokal.id === grupa.id_lokal);
            return lokal && lokal.rodzaj === "mieszkanie";
          });
        return wynik;          
    }

    const tab = udzialyKontaAkt?.map( (udzial, i) => {

        const mieszkaniec = mieszkancy.find( mieszkaniec => mieszkaniec.id_user == udzial.id_user);

        const grupaMieszkanie = findGrupaZMieszkaniem(udzial, grupy, lokale)[0];

        const grupyMsc = czynszeWspolnota?.find( czynsz => czynsz?.id_user == udzial?.id_user)?.grupy_msc?.find( c => c?.grupa_numer == udzial.grupa_numer);

        const przelewyRokUdzial = czyRok && przelewyRok?.length > 0 ? przelewyRok?.find( p => p?.id_user == udzial?.id_user && p?.grupa_numer == udzial?.grupa_numer).kwota : 0;
        const czynszeRokUdzial = czyRok && czynszeRok?.length > 0 ? czynszeRok?.find( c => c?.id_user == udzial?.id_user && udzial?.grupa_numer == udzial?.grupa_numer).kwota : 0;

        const rok = czyRok && (
            <td style={{fontWeight: "600", whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)"}}>
                { zl( przelewyRokUdzial - czynszeRokUdzial )}
            </td>
        );

        const sumaNaleznosciTotal = czynszeTotal?.find( c => c?.id_user == udzial?.id_user && c?.grupa_numer == udzial?.grupa_numer )?.kwota;
        const sumaPrzelewyTotal = przelewyTotal?.find( c => c?.id_user == udzial?.id_user && c?.grupa_numer == udzial?.grupa_numer )?.kwota;

        const sumaNaleznosciRokObecny = czynszeRokObecny?.find( c => c?.id_user == udzial?.id_user && c?.grupa_numer == udzial?.grupa_numer)?.kwota;
        const sumaPrzelewyRokObecny = przelewyRokObecny?.find( c => c?.id_user == udzial?.id_user && c?.grupa_numer == udzial?.grupa_numer)?.kwota;

        const sumaNaleznosciWidoczne = czynszeWidoczne?.find( c => c?.id_user == udzial?.id_user && c?.grupa_numer == udzial?.grupa_numer )?.kwota;
        const sumaPrzelewyWidoczne = przelewyWidoczne?.find( c => c?.id_user == udzial?.id_user && c?.grupa_numer == udzial?.grupa_numer )?.kwota;
        
        const miesiace = grupyMsc && listaMsc?.map( msc => {

            const czynszTemp = grupyMsc?.temp_msc?.find( g => g?.msc == msc)?.czynsz_na_msc;
            const czynszLast = grupyMsc?.temp_msc[grupyMsc?.temp_msc?.length -1]?.czynsz_na_msc;

            const czynsz_na_msc = czynszTemp === undefined && mm_rrrr_to_date(msc) >= mm_rrrr_to_date(grupyMsc?.temp_msc[grupyMsc?.temp_msc?.length -1].msc) ? czynszLast : czynszTemp

            // sumaNaleznosciTotal += isNumber(czynsz_na_msc) ? czynsz_na_msc : 0;

            const przelewy = przelewyWspolnota?.find( p => p?.id_user == udzial?.id_user && p?.nr_grupyUdzial == udzial?.grupa_numer)?.przelewy;

            let sumaPrzelewy = 0;

            const przelewyMsc = przelewy?.filter( p => p?.msc == msc)
            
            sumaPrzelewy += przelewyMsc?.reduce((acc, obj) => {
                return acc + ( isNumber(obj.kwota) ? obj.kwota : 0 );
            },0);
            // sumaPrzelewyTotal += sumaPrzelewy;
            
            if (!visible) {
                return (
                    <></>
                )
            } else {
                return (
                    <>
                        <td style={{whiteSpace: "nowrap" }}>{`${zl(czynsz_na_msc)}`}</td>
                        <td style={{whiteSpace: "nowrap" }}>{zl(sumaPrzelewy)}</td>
                        <td style={{fontWeight: "600", whiteSpace: "nowrap" }}>{ zl(sumaPrzelewy - czynsz_na_msc)}</td>
                    </>
                )
            }
        })

        const podsumowanie = (
            <>
                <td style={{whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)" }}>{zl(sumaNaleznosciWidoczne)}</td>
                <td style={{whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)" }}>{zl(sumaPrzelewyWidoczne)}</td>
                <td style={{fontWeight: "600", whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)" }}>{ zl(sumaPrzelewyWidoczne - sumaNaleznosciWidoczne)}</td>
            </>
        )
        // sumaNaleznosciTotal = 0;
        // sumaPrzelewyTotal = 0;

        const nrGrupyTxt = idw_temp !== null ? `, (nr gr.: ${udzial?.grupa_numer})` : '';
        const idUserTxt = idw_temp !== null ? ` (id ${mieszkaniec?.id_user})` : '';

        const adresTd = adresPro(grupaMieszkanie?.id_lokal, lokale, ulice);

        return (   
            <tr key={i}>
                <td style={{whiteSpace: "nowrap" }}>
                    {`${mieszkaniec?.imie_nazwisko}${idUserTxt}`}
                </td>
                <td style={{whiteSpace: "nowrap" }}>
                    {`${ adresTd?.length > 0 ? adresTd : "brak mieszkania" }${nrGrupyTxt}`}
                </td>
                    <>
                        {rok}
                        {miesiace}
                        {podsumowanie}
                    </>
            </tr>
        )
    }) 

    return (
        <tbody>
            {tab}
        </tbody>
    ) 
}

export default WspolnotaRaportZbiorowyTBody;