export const adres = ( (id_ulica, ulice, id_lokal, lokale) => {

  const rekord_ = ulice?.filter( u => u.id === id_ulica);
  const rekord = rekord_ && rekord_.length > 0 ? rekord_[0] : undefined;

  const lokal_ = lokale?.filter( l => l.id === id_lokal);
  const lokal = lokal_ && lokal_.length > 0 ? lokal_[0]?.numer : undefined;
  
  return rekord ? rekord?.rodzaj + " " + rekord?.nazwa + " " + rekord?.numer_budynku + " / " + lokal : "";

});

export const adresyPro = ((udzialyGrupa, lokale, ulice, grupy, udzialyKonta_w, rodzajLokalu = "mieszkanie")  => {

  const temp = udzialyGrupa?.map( u => {
    
    const id_lokal = findMieszkanieId(u, grupy, lokale, rodzajLokalu);
    const lokal = lokale?.find(l=>l?.id == id_lokal);
    const czyZamkniety = grupy?.find( g => g?.id_lokal == id_lokal && g?.id_user == u?.id_user && g?.grupa_numer == u.grupa_numer)?.data_do !== null;
    return {
      lokal_nr: lokal?.numer ? parseInt(lokal?.numer) : 0,
      id_user: u?.id_user,
      grupa_numer: u?.grupa_numer,
      adres: adresPro(id_lokal, lokale, ulice),
      udzialGrupa: udzialyKonta_w?.find( uk => uk?.id_user == u?.id_user && uk?.grupa_numer == u?.grupa_numer),
      grupy: u,
      czyZamkniety
    }
    
  })
  return temp.slice().sort((a, b) => a.lokal_nr - b.lokal_nr);

});

export const AdresPro2 = (udzialKonto, lokale, ulice, grupy, rodzajLokalu = "mieszkanie" ) => {
  const id_lokal = findMieszkanieId(udzialKonto, grupy, lokale, rodzajLokalu);
  
  if (id_lokal && id_lokal != "" ) {
    const lokal = lokale?.find( l => l.id == id_lokal);
    const ulica = ulice?.find( u => u.id == lokal.ulica );
    if (lokal?.numer !== "")
      return `${ulica?.rodzaj} ${ulica?.nazwa} ${ulica?.numer_budynku} / ${lokal?.numer}`
    else 
      return `${ulica?.rodzaj} ${ulica?.nazwa} ${ulica?.numer_budynku}`
  }
  else 
    return "";  
}

export const adresPro = ( (id_lokal, lokale, ulice) => {

  if (id_lokal && id_lokal != "" ) {
    const lokal = lokale?.find( l => l.id == id_lokal);
    const ulica = ulice?.find( u => u.id == lokal.ulica );
    if (lokal?.numer !== "")
      return `${ulica?.rodzaj} ${ulica?.nazwa} ${ulica?.numer_budynku} / ${lokal?.numer}`
    else 
      return `${ulica?.rodzaj} ${ulica?.nazwa} ${ulica?.numer_budynku}`
  }
  else 
    return "";  

});

export const rodzajLokalu = r => {
  switch(r) {
    case "mieszkanie":
      return "(m) ";
    case "garaż":
      return "(g) ";
    case "komórka":
      return "(k) ";
    case "strych":
      return "(s) "
    default:
      return "";
  }
}

export const findMieszkanieId = (udzialKonto, grupy, lokale, rodzajLokalu = "mieszkanie") => {
  const przeszukaneGrupy = grupy?.filter(g => g.id_user == udzialKonto?.id_user && g.grupa_numer == udzialKonto?.grupa_numer);
  let id_mieszkanie; 
  przeszukaneGrupy?.forEach( g => {
    const temp = lokale?.find( l => l.id == g.id_lokal && l.rodzaj === rodzajLokalu)?.id;
    if (temp !== undefined) 
      id_mieszkanie = temp;
  })
  return id_mieszkanie;
}

export const  polaczUliceLokale = (ulice, lokale) => {
  return lokale.map(lokal => {
    const ulica = ulice.find(u => u.id === lokal.ulica);

    if (ulica) {
        return {
            id_lokalu: lokal.id,  // Zmieniamy nazwę pola id z tablicy lokale
            rodzaj_ulicy: ulica.rodzaj,
            ...ulica,
            ...lokal,
        };
    }

    return {
        id_lokalu: lokal.id,  // Zmieniamy nazwę pola id z tablicy lokale
        ...lokal,
    };
});
}

export const sortPolaczone = (tablica) => {
  return tablica.sort((a, b) => {
    console.log(a)
      if (a.nazwa.toLowerCase() < b.nazwa.toLowerCase()) {
          return -1;
      }
      if (a.nazwa.toLowerCase() > b.nazwa.toLowerCase()) {
          return 1;
      }

      if (a.numer_budynku < b.numer_budynku) {
          return -1;
      }
      if (a.numer_budynku > b.numer_budynku) {
          return 1;
      }

    if (a.rodzaj.toLowerCase() < b.rodzaj.toLowerCase()) {
        return -1;
    }
    if (a.rodzaj.toLowerCase() > b.rodzaj.toLowerCase()) {
        return 1;
    }

      if (parseInt(a.numer) < parseInt(b.numer)) {
          return -1;
      }
      if (parseInt(a.numer) > parseInt(b.numer)) {
          return 1;
      }

      return 0; // Jeśli wszystkie pola są równe
  });
}