import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MieszkaniecDaneStawkiIndList from "./MieszkaniecDaneStawkiIndList.js";
import MieszkaniecDaneStawkiIndAdd from "./MieszkaniecDaneStawkiIndAdd.js";

const MieszkaniecDaneStawkiInd = props => {

    const { grupaUdzial, stawki_w, stawkiInd_g } = props;

    const [addNew, setAddNew] = useState(false)

    const [stawkaId, setStawkaId] = useState(undefined);
   
    return (
        <div className="stawkiInd ramka">
            <div className="naglowek">
                <h2>
                    Stawki indywidualne 
                </h2> 
                {
                    !addNew ? (
                        <div className="ikony">
                            <FontAwesomeIcon 
                                icon={faPlus} 
                                className="pointer" 
                                onClick={()=>setAddNew(true)}
                            />
                        </div>
                    ) : (
                        <></>
                    )
                }

            </div>
  
            {
                !addNew ? (
                    <MieszkaniecDaneStawkiIndList
                        grupaUdzial={grupaUdzial}
                        stawki_w={stawki_w}
                        stawkiInd_g={stawkiInd_g}
                        stawkaId={stawkaId}
                        setStawkaId={setStawkaId}
                    />
                ) : (
                    <MieszkaniecDaneStawkiIndAdd
                        grupaUdzial={grupaUdzial}
                        stawki_w={stawki_w}
                        stawkiInd_g={stawkiInd_g}
                        setAddNew={setAddNew}
                    />
                )
            }
        </div>
    )
}

export default MieszkaniecDaneStawkiInd;